import React from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { getStatusCampain } from '../../../utils';
import { useMobileDetect } from '../../../hooks';

export function ProgressBar(props) {
    const isMobile = useMobileDetect();

    const statusCampain = getStatusCampain(props.value);

    const { color, status } = statusCampain;

    const linearProgressBar = () => (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress
                    variant="determinate"
                    value={props.value}
                    sx={{
                        width: 160,
                        height: 15,
                        borderRadius: 5,
                        backgroundColor: status !== 'Cancelado' ? 'var(--grey-400-color)' : color,
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: color,
                        },
                    }}
                />
            </Box>

            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="button"
                    color="text.secondary"
                    sx={{
                        fontWeight: '900',
                        lineHeight: 1,
                        color: 'var(--primary-dark-color)',
                        fontSize: '0.775rem',
                    }}
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );

    const circularProgressBar = () => (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                variant="determinate"
                {...props}
                sx={{
                    color: status !== 'Cancelado' ? 'var(--grey-400-color)' : color,
                }}
                thickness={5}
                size={50}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                {...props}
                sx={{
                    color: color,
                    position: 'absolute',
                    left: 0,
                }}
                thickness={5}
                size={50}
                value={props.value}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontWeight: '900',
                        lineHeight: 1,
                        color: 'var(--primary-dark-color)',
                        fontSize: '0.775rem',
                    }}
                >
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );

    return <>{isMobile ? circularProgressBar() : linearProgressBar()}</>;
}

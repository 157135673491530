import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';

export const FeedsHeader = ({ setIsGridView, isGridView, setOpenModalConfig, handleDelete, selectedFeed }) => {
    const darkGrey = 'var(--grey-900-color)';
    const grey = 'var(--grey-400-color)';

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
                pb: 2,
                borderRadius: '8px',
            }}
        >
            <Typography variant="h4">{selectedFeed?.nome}</Typography>

            <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton onClick={() => setIsGridView(true)}>
                    <ViewModuleIcon sx={{ color: isGridView ? darkGrey : grey }} />
                </IconButton>
                <IconButton onClick={() => setIsGridView(false)}>
                    <ViewListIcon sx={{ color: !isGridView ? darkGrey : grey }} />
                </IconButton>

                <IconButton onClick={() => setOpenModalConfig(true)}>
                    <SettingsIcon sx={{ color: darkGrey }} />
                </IconButton>
                <IconButton onClick={handleDelete}>
                    <DeleteIcon sx={{ color: darkGrey }} />
                </IconButton>
            </Box>
        </Box>
    );
};

import { Post } from '../http/httpVerbs';

export const apiPostChat = async (item) => {
    try {
        const response = await Post(`${process.env.REACT_APP_API_URL}/chat`, {
            mensagem: item.mensagem,
            idusuario: item.idusuario,
            idcliente: item.idcliente,
            idclienteusuario: item.idclienteusuario,
            idcreator: item.idcreator,
            idcampanha: item.idcampanha,
            idcampanhaitem: item.idcampanhaitem,
            contexto: item.contexto,
        });

        return response;
    } catch (error) {
        throw error;
    }
};

import { Alert } from '@mui/material';

export const Message = ({ variant, message }) => {
    return (
        <>
            {message && (
                <Alert
                    variant="filled"
                    severity={variant}
                    sx={{ justifyContent: 'center', height: 50, alignItems: 'center', fontSize: 16 }}
                >
                    {message}
                </Alert>
            )}
        </>
    );
};

import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Autocomplete, TextField } from '@mui/material';
import Processando from '../../../_assets/Processando.png';
import { CampainCardDetailsModal } from '../../ui/CampainCardDetailsModal';
import { VideoThumbnail } from '../../ui/VideoThumbnail';
import {
    handleOpenCampanhaItemFromNotification,
    storageCampanhaItem,
    handleClearStorage,
} from '../../../utils/handleStorage.utils';
import ButtonFilter from '../../ui/ButtonFilter';
import { useMobileDetect } from '../../../hooks';
import { placeholder } from '../../../utils';

export const VideosTab = ({ campanhaItens, setIsLoading, setUpdated }) => {
    const [openModal, setOpenModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [filter, setFilter] = useState('');
    const [itemsLoaded, setItemsLoaded] = useState(false);

    const isMobile = useMobileDetect();

    const uniqueCreators = Array.from(new Set(campanhaItens.map((item) => item.creatorName)));

    const handleOpenModal = (item) => {
        setSelectedItem(item);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setSelectedItem(null);
        setOpenModal(false);
    };

    useEffect(() => {
        if (campanhaItens && campanhaItens.length > 0) {
            setItemsLoaded(true);
        }
    }, [campanhaItens]);

    useEffect(() => {
        if (itemsLoaded) {
            const shouldOpen = handleOpenCampanhaItemFromNotification();
            if (shouldOpen && storageCampanhaItem) {
                handleOpenModal(campanhaItens.filter((i) => i.id === storageCampanhaItem)[0]);
                handleClearStorage();
            }
        }
    }, [itemsLoaded]);

    return (
        <Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginBottom: 2 }}>
                {uniqueCreators.length <= 6 ? (
                    <>
                        <ButtonFilter onClick={() => setFilter('')}>Todos</ButtonFilter>
                        {uniqueCreators.map((creatorName) => (
                            <ButtonFilter
                                key={creatorName}
                                onClick={() => setFilter(creatorName ?? placeholder)}
                                onChange
                            >
                                {creatorName ?? placeholder}
                            </ButtonFilter>
                        ))}
                    </>
                ) : (
                    <Autocomplete
                        options={uniqueCreators}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                            <TextField {...params} label="Filtrar por creator" fullWidth variant="outlined" />
                        )}
                        onChange={(event, newValue) => setFilter(newValue ?? '')}
                        renderOption={(props, option) => <li {...props}>{option}</li>}
                        sx={{ width: isMobile ? '100%' : 700, my: 4 }}
                    />
                )}
            </Box>

            <Typography variant="h4" component="h2" gutterBottom>
                Vídeos aprovados
            </Typography>
            <Grid container width="100%" justifyContent="left">
                {campanhaItens.length > 0 &&
                    campanhaItens
                        ?.filter(
                            (item) =>
                                (filter === '' || item.creatorName === filter) &&
                                item.etapa !== 'uploadVideo' &&
                                item.etapa !== 'uploadVideoTexto' &&
                                item.idVideo > 0,
                        )
                        ?.filter(
                            (item) =>
                                (filter === '' || item.creatorName === filter) &&
                                item.etapa != 'uploadVideo' &&
                                item.etapa != 'uploadVideoTexto' &&
                                item.idVideo > 0,
                        )
                        ?.map((item) => {
                            const image = item?.videoFile_capa ? item?.videoFile_capa : Processando;

                            return (
                                <VideoThumbnail
                                    key={item.id}
                                    item={item}
                                    coverImage={image}
                                    frames={item?.videoFile_capas}
                                    handleOpenModal={handleOpenModal}
                                />
                            );
                            return (
                                <VideoThumbnail
                                    key={item.id}
                                    item={item}
                                    coverImage={image}
                                    frames={item?.videoFile_capas}
                                    handleOpenModal={handleOpenModal}
                                />
                            );
                        })}
            </Grid>

            {openModal && (
                <CampainCardDetailsModal
                    item={selectedItem}
                    open={openModal}
                    onClose={handleCloseModal}
                    setIsLoading={setIsLoading}
                    setUpdated={setUpdated}
                />
            )}
        </Box>
    );
};

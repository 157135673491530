import React from 'react';
import { Box, Typography, Stepper, Step, StepLabel, styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useMobileDetect } from '../../../../hooks';

const CustomStepConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 20,
        left: 'calc(-50% + 25px)',
        right: 'calc(50% + 25px)',
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: 'var(--white-color)',
    },
}));

const useResponsiveStyles = () => {
    const isMobile = useMobileDetect();

    const circleStyleBase = {
        width: isMobile ? 35 : 40,
        height: isMobile ? 35 : 40,
        borderRadius: '50%',
        color: 'var(--white-color)',
    };

    return { circleStyleBase, isMobile };
};

const getCircleStyle = (etapa, item, circleStyleBase) => ({
    ...circleStyleBase,
    backgroundColor:
        etapa.atual && etapa.etapa !== 'completed'
            ? 'var(--warning-color)'
            : etapa.concluido || item.status === 'COMPLETED'
            ? 'var(--success-color)'
            : 'var(--grey-700-color)',
    cursor: etapa.concluido || etapa.atual ? 'pointer' : 'default',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const StepIcon = ({ etapa, item, handleModal }) => {
    const { circleStyleBase, isMobile } = useResponsiveStyles();
    const circleStyle = getCircleStyle(etapa, item, circleStyleBase);

    const handleClick = () => {
        if (etapa.concluido || etapa.atual) {
            handleModal({ etapa });
        }
    };

    const fontSize = isMobile ? 'inherit' : 'small';

    return (
        <Box onClick={handleClick} style={circleStyle}>
            {etapa.concluido || item.status === 'COMPLETED' ? (
                <CheckIcon fontSize={fontSize} />
            ) : etapa.atual ? (
                <HourglassFullIcon fontSize={fontSize} />
            ) : (
                <AccessTimeIcon fontSize={fontSize} />
            )}
        </Box>
    );
};

const CustomScrollBar = styled(Box)(({ isMobile }) => ({
    width: '100%',
    height: isMobile ? 100 : 150,
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
        height: '12px',
        display: isMobile ? 'none ' : 'block',
    },
    '&::-webkit-scrollbar-track': {
        background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
        background: 'var(--primary-color)',
        borderRadius: '5px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: 'var(--grey-600-color)',
    },
}));

export const CampainWorkflowSteps = ({ item, handleModal }) => {
    const isMobile = useMobileDetect();

    return (
        <CustomScrollBar isMobile={isMobile}>
            <Stepper alternativeLabel connector={<CustomStepConnector />}>
                {item?.etapas?.map((etapa) => {
                    const isWaiting = (etapa?.atual === 0 && etapa?.concluido === 0) || etapa?.concluido === 1;

                    return (
                        <Step key={etapa.id}>
                            <StepLabel
                                StepIconComponent={() => (
                                    <StepIcon
                                        etapa={etapa}
                                        item={item}
                                        handleModal={handleModal}
                                        isWaiting={isWaiting}
                                    />
                                )}
                            >
                                <Typography
                                    style={{
                                        color: isWaiting ? 'var(--grey-600-color)' : 'var(--white-color)',
                                        fontSize: isMobile ? 12 : 14,
                                        width: item.etapas?.length < 5 && !isMobile ? null : isMobile ? 90 : 120,
                                    }}
                                >
                                    {etapa.etapaTitulo}
                                </Typography>
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </CustomScrollBar>
    );
};

import React, { useState, useEffect } from 'react';
import { Box, CardHeader, Card, CardContent, Typography } from '@mui/material';

import { isBefore, isEqual } from 'date-fns';
import { FlagStatus } from '../FlagStatus';
import { getStatusStages, getStageCampain, getIconByCategory, formatDate, placeholder } from '../../utils';

import { useErrorStore } from '../../store';

import { IcampanhasItensStatus } from '../../data/Interfaces';

export const CampainCard = ({ campain, handleOpenModal, children }) => {
    const [flagStatusData, setFlagStatusData] = useState(null);
    const [flagStageData, setFlagStageData] = useState(null);

    const completedDate = formatDate(campain?.completedDate);
    const completedDateRaw = new Date(campain?.completedDate);
    const currentDate = new Date();

    const isPastDue =
        completedDate && (isBefore(completedDateRaw, currentDate) || isEqual(completedDateRaw, currentDate));
    const color =
        isPastDue && campain?.status !== IcampanhasItensStatus.COMPLETED ? 'var(--error-color)' : 'var(--black-color)';

    const icon = getIconByCategory(campain?.categoria);

    const showError = useErrorStore((state) => state.showError);

    useEffect(() => {
        try {
            const getCampaignInformation = async () => {
                const statusInfo = getStatusStages(campain?.status);
                setFlagStatusData(statusInfo);
                const stageData = await getStageCampain(campain?.etapa, campain?.workflow);
                setFlagStageData(stageData);
            };

            getCampaignInformation();
        } catch (error) {
            showError(error);
        }
    }, [campain]);

    return (
        <Box key={campain.id} sx={{ padding: 1, cursor: 'pointer' }} onClick={() => handleOpenModal(campain)}>
            <Card
                key={campain.id}
                sx={{
                    boxShadow: 'none',
                    bgcolor: 'var(--grey-50-color)',
                    borderRadius: 3,
                    minHeight: '20vh',
                    width: '320px',
                }}
            >
                <CardHeader
                    title={
                        <Typography
                            sx={{
                                fontSize: '0.9rem',
                                color: 'white',
                            }}
                        >
                            {campain?.creatorName ?? placeholder}
                        </Typography>
                    }
                    sx={{
                        backgroundColor: 'var(--primary-color)',
                        padding: 0.5,
                        textAlign: 'center',
                    }}
                />
                <CardContent>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        {completedDate && <FlagStatus color={color} text={completedDate} />}
                        {flagStatusData && <FlagStatus color={flagStatusData.color} text={flagStatusData?.text} />}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
                                {icon}
                                <Typography variant="body" sx={{ mx: 0.5 }}>
                                    {campain.titulo
                                        ? `${campain.categoria} - ${campain.subcategoria}`
                                        : campain.categoria}
                                </Typography>
                            </Box>
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{ mt: 1, wordBreak: 'break-word', overflowWrap: 'break-word' }}
                            >
                                {campain.titulo || campain.subcategoria}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>

                {children}
            </Card>
        </Box>
    );
};

import { Patch } from '../http/httpVerbs';

export const apiUpdateCampanha = async (id, campanha) => {
    try {
        const response = await Patch(`${process.env.REACT_APP_API_URL}/campanhas/${id}`, campanha);
        return response;
    } catch (error) {
        throw error;
    }
};

import { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import InputMask from 'react-input-mask';

import { apiUpdateUser } from '../../services/apiUpdateUser';
import { useAuthStore, useErrorStore } from '../../store';

export const AlterarDados = () => {
    const user = useAuthStore((state) => state.user);
    const updateUser = useAuthStore((state) => state.updateUser);
    const showError = useErrorStore((state) => state.showError);

    const [form, setForm] = useState({
        nome: '',
        email: '',
        telefone: '',
    });

    useEffect(() => {
        if (user) {
            setForm({
                nome: user.nome || '',
                email: user.email || '',
                telefone: user.telefone || '',
            });
        }
    }, [user]);

    const handleSubmit = (event) => {
        try {
            event.preventDefault();

            const updated = {
                ...user,
                nome: form.nome,
                email: form.email,
                telefone: form.telefone,
            };

            updateUser(updated);

            apiUpdateUser(user.id, updated);
        } catch (error) {
            showError(error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'top',
                p: 0,
                backgroundColor: 'var(--white-color)',
            }}
        >
            <Typography component="h1" variant="h5" sx={{ marginTop: 10 }}>
                Editar Dados
            </Typography>

            <Box component="form" onSubmit={handleSubmit} sx={{ p: 0, width: '90%', mb: 20, mt: 2, border: 0 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="nome"
                    label="Nome do Usuário"
                    id="nome"
                    value={form.nome}
                    onChange={handleInputChange}
                    autoComplete="Nome do Usuário"
                />
                <TextField
                    margin="normal"
                    type="email"
                    required
                    fullWidth
                    name="email"
                    label="E-mail"
                    id="email"
                    value={form.email}
                    onChange={handleInputChange}
                    autoComplete="E-mail"
                />
                <InputMask
                    mask="(99) 99999-9999"
                    required
                    fullWidth
                    name="telefone"
                    label="Telefone"
                    id="telefone"
                    autoComplete="Telefone"
                    margin="normal"
                    value={form.telefone}
                    onChange={handleInputChange}
                >
                    {(inputProps) => <TextField {...inputProps} />}
                </InputMask>

                <Button type="submit" fullWidth variant="contained" color="success" sx={{ mt: 3, mb: 2 }}>
                    Salvar
                </Button>
            </Box>
        </Box>
    );
};

import React, { useState, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tab, Tabs, Modal } from '@mui/material';
import { IcampanhasItensStatus } from '../../../../data/Interfaces';
import { CampaignActionButtonsCard } from '../../CampainActionButtonsCard';

import { MediaViewer } from '../../MediaViewer';
import { TextEditor } from '../../../ui/TextEditor';
import VideoInfoCard from '../../VideoInfoCard';
import { identifyFileType } from '../../../../utils';

export const CampainWorkflowStepDetails = ({
    open,
    onClose,
    item,
    etapa,
    handleOnAproveCampanhaItem,
    handleOpenItemReproveModal,
    videoInfo,
    campanhaItens,
}) => {
    const briefing = decodeURIComponent(item?.escopoBriefing || item?.campanhaBriefing || '');

    const typeFile = identifyFileType(etapa?.dados[0]?.valor);

    const isImage = typeFile === 'image';
    const isVideo = typeFile === 'video';
    const isText = etapa?.dados[0]?.tipo === 'texto';
    const isDownloadFile = (typeFile === 'unknown' || typeFile === 'document') && etapa?.dados[0]?.tipo !== 'texto';

    const [tabValue, setTabValue] = useState(isVideo ? 0 : 1);
    const [frameSrc, setFrameSrc] = useState('');

    const handleTabChange = (event, newValue) => setTabValue(newValue);
    const handleFrameClick = (src) => setFrameSrc(src);

    const renderCampaignActionButtons = useMemo(() => {
        const isWaitingForClient = item?.status === IcampanhasItensStatus.WAITING_CLIENT;
        const isInCampaignList = campanhaItens?.some((campaign) => campaign?.id === item.id);
        const isStepIncomplete = etapa?.concluido !== 1;

        const shouldRenderButtons = isWaitingForClient && isInCampaignList && isStepIncomplete;

        if (shouldRenderButtons) {
            return (
                <Box width="50vh">
                    <CampaignActionButtonsCard
                        selectedItem={item}
                        handleOnAproveCampanhaItem={handleOnAproveCampanhaItem}
                        handleOpenItemReproveModal={handleOpenItemReproveModal}
                    />
                </Box>
            );
        }

        return null;
    }, [item, etapa, handleOnAproveCampanhaItem, handleOpenItemReproveModal, campanhaItens]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                backgroundColor: 'rgba(0,0,0,0.7)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'auto',
                border: 'none',
            }}
        >
            <>
                <IconButton
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        top: 10,
                        right: 20,
                        padding: '10px',
                        fontSize: '30px',
                        color: 'var(--white-color)',
                        cursor: 'pointer',
                        zIndex: 999,
                    }}
                    aria-label="close-video-player"
                >
                    <CloseIcon fontSize="large" />
                </IconButton>

                <Box
                    sx={{
                        bgcolor: 'var(--grey-50-color)',
                        p: 4,
                        width: '80%',
                        height: '75vh',
                        overflow: 'auto',
                    }}
                    borderRadius={4}
                    display="flex"
                    flexDirection={{ xs: 'column', md: 'row' }}
                    gap={4}
                >
                    <Box
                        flex={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bgcolor={!isVideo ? 'var(--grey-50-color)' : 'rgba(0, 0, 0, 0.8)'}
                        borderRadius={4}
                    >
                        <MediaViewer
                            isVideo={isVideo}
                            isImage={isImage}
                            isDownloadFile={isDownloadFile}
                            isText={isText}
                            typeFile={typeFile}
                            etapa={etapa}
                        />
                    </Box>
                    <Box flex={1} display="flex" flexDirection="column">
                        <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth">
                            {videoInfo && <Tab label="Informações" />}
                            <Tab label="Briefing" />
                        </Tabs>
                        <Box flex={1} overflow="auto">
                            {tabValue === 0 && (
                                <VideoInfoCard videoInfo={videoInfo} handleFrameClick={handleFrameClick} />
                            )}
                            {tabValue === 1 && (
                                <Box>
                                    <TextEditor editorContent={briefing} isReadOnly />
                                </Box>
                            )}
                        </Box>
                        <DialogActions>{renderCampaignActionButtons}</DialogActions>
                    </Box>
                </Box>
                {frameSrc && (
                    <Dialog open={Boolean(frameSrc)} onClose={() => setFrameSrc('')}>
                        <DialogTitle>Amostra</DialogTitle>
                        <DialogContent>
                            <Box textAlign="center">
                                <img src={frameSrc} alt="Amostra" style={{ maxWidth: '100%', maxHeight: '70vh' }} />
                            </Box>
                        </DialogContent>
                    </Dialog>
                )}
            </>
        </Modal>
    );
};

import { Patch } from '../http/httpVerbs';

export const apiPatchFeed = async (feedId, feed) => {
    try {
        const response = await Patch(`${process.env.REACT_APP_API_URL}/feeds/${feedId}`, feed);

        return response;
    } catch (error) {
        throw error;
    }
};

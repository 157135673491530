import React, { useEffect } from 'react';
import { Routes } from './routes';
import { useAuthStore, useNotificationStore, ErrorProvider, useErrorStore } from './store';

export function App() {
    const fetchUser = useAuthStore((state) => state.fetchUser);
    const fetchNotifications = useNotificationStore((state) => state.fetchNotifications);
    const showError = useErrorStore((state) => state.showError);

    useEffect(() => {
        try {
            const fetchData = async () => {
                await fetchUser();
                await fetchNotifications();
            };

            fetchData();
        } catch (error) {
            showError(error);
        }
    }, [fetchUser, fetchNotifications]);

    return (
        <ErrorProvider>
            <Routes />
        </ErrorProvider>
    );
}

import React, { useState } from 'react';
import { Box } from '@mui/material';
import { apiUpdateCampanha } from '../../../services';
import { useErrorStore } from '../../../store';
import { TextEditor } from '../../../components/ui/TextEditor';
import { ButtonFL } from '../../../components/ui/ButtonFL';
import { Loader } from '../../../components/ui/Loader';

export const BriefingTab = ({ campanha }) => {
    const [isLoading, setIsLoading] = useState(false);

    const briefing = decodeURIComponent(campanha?.briefing || '');
    const [editorContent, setEditorContent] = useState(briefing);

    const showError = useErrorStore((state) => state.showError);

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const campanhaSave = {
                ...campanha,
                briefing: editorContent,
            };

            await apiUpdateCampanha(campanha.id, campanhaSave);
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && <Loader />}
            <Box>
                <TextEditor setEditorContent={setEditorContent} editorContent={editorContent} />
                <ButtonFL textKey="Salvar" onClick={handleSave} fullWidth />
            </Box>
        </>
    );
};

import { Post } from '../http/httpVerbs';

export const apiUpdatePassword = async (token, newPassword) => {
    try {
        const response = await Post(`${process.env.REACT_APP_API_URL}/usuarios/updatePassword`, {
            token,
            newPassword,
        });

        return response;
    } catch (error) {
        throw error;
    }
};

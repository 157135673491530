import { Get } from '../http/httpVerbs';

export const apiGetFeeds = async (clienteFeed) => {
    try {
        const response = await Get(`${process.env.REACT_APP_API_URL}/feeds?clienteFeed=${clienteFeed}`);

        return response;
    } catch (error) {
        throw error;
    }
};

import React from 'react';
import { Button } from '@mui/material';

export const ButtonFL = ({
    variant = 'contained',
    textColor = 'var(--grey-50-color)',
    component = null,
    bgcolor = 'var(--primary-color)',
    bgColorHover = 'var(--primary-light-color)',
    type = 'button',
    textKey,
    keyPrefix,
    to = null,
    onClick = null,
    buttonDisabled,
    startIcon = null,
    endIcon = null,
    children,
    fullWidth,
    ...props
}) => {
    const buttonStyles = {
        textTransform: 'none',
        bgcolor: variant === 'text' ? 'transparent' : bgcolor,
        '&:hover': { bgcolor: variant === 'text' ? 'transparent' : bgColorHover, color: textColor },
        color: textColor,
    };

    return (
        <>
            <Button
                variant={variant}
                disabled={buttonDisabled}
                type={type}
                fullWidth={fullWidth}
                sx={{ ...props.sx, ...buttonStyles }}
                to={to}
                onClick={onClick}
                startIcon={startIcon}
                endIcon={endIcon}
                component={component}
            >
                {textKey}
                {children}
            </Button>
        </>
    );
};

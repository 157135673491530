import React from 'react';
import { ChevronLeft, Menu, Settings, KeyboardArrowRight, Close } from '@mui/icons-material';
import { Divider, IconButton, Drawer as MuiDrawer, styled, Button, Box } from '@mui/material';
import logo from '../../../_assets/logo.png';
import logoSimbolo from '../../../_assets/logo-simbolo.png';
import { ListItems } from '../../../components/ui/ListItems';
import { ProfileMenu } from '../../../components/ui/ProfileMenu';
import { useAuthStore } from '../../../store';

const drawerWidth = 200;

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: open ? drawerWidth : theme.spacing(7),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        overflowX: 'hidden',
        backgroundColor: 'var(--primary-dark-color)',
        color: 'var(--white-color)',
        [theme.breakpoints.up('sm')]: {
            width: open ? drawerWidth : theme.spacing(9),
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    '& .MuiSvgIcon-root': {
        fill: 'var(--grey-50-color)',
    },
}));

const UserSection = ({ open, toggleDrawerMobile }) => (
    <Box
        sx={{
            position: 'fixed',
            bottom: 50,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: open ? drawerWidth : drawerWidth / 3,
        }}
    >
        <Button edge="end" aria-label="logout user" onClick={toggleDrawerMobile}>
            <Settings fontSize="large" />
        </Button>
    </Box>
);

export const DashboardDrawerMenu = ({ open, toggleDrawer }) => {
    const user = useAuthStore((state) => state.user);

    const [openDrawerMobile, setOpenDrawerMobile] = React.useState(false);

    const toggleDrawerMobile = (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenDrawerMobile(!openDrawerMobile);
    };

    return (
        <Box sx={{ display: 'flex', position: 'relative' }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: -10,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 9999,
                    bgcolor: 'var(--primary-dark-color)',
                    color: 'var(--white-color)',
                }}
            >
                <IconButton onClick={toggleDrawer} sx={{ p: 0, color: 'var(--white-color)' }}>
                    {open ? <Close fontSize="small" /> : <KeyboardArrowRight />}
                </IconButton>
            </Box>
            <Drawer variant="permanent" open={open}>
                <Box>
                    {open ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2, mt: 1 }}>
                            <img src={logo} alt="logo" style={{ maxWidth: '100%' }} />
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2, mt: 1.5 }}>
                            <img src={logoSimbolo} alt="logoSimbolo" style={{ maxWidth: '100%' }} />
                        </Box>
                    )}
                    <Divider />
                    <Box sx={{ pt: 5 }}>
                        <ListItems open={open} />
                    </Box>
                    <UserSection open={open} toggleDrawerMobile={toggleDrawerMobile} />
                </Box>
            </Drawer>
            <ProfileMenu openDrawer={openDrawerMobile} toggleDrawer={toggleDrawerMobile} creator={user} />
        </Box>
    );
};

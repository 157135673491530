import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Menu, MenuItem, IconButton } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import logo from '../../../_assets/logo.png';
import './header.css';
import { NotificationMenu } from '../../NotificationMenu';
import { useAuthStore } from '../../../store';

export const Header = () => {
    const { user, logout } = useAuthStore((state) => ({
        user: state.user,
        logout: state.logout,
    }));

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAlterarDados = () => {
        navigate('/alterarDados');
    };

    const handleAlterarSenha = () => {
        navigate('/createNewPassword');
    };

    return (
        <AppBar position="static" className="header">
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <div className="containerLogo">
                    <img src={logo} className="logo" alt="logo" />
                </div>
                {user && (
                    <div style={{ display: 'flex', alignItems: 'center', paddingRight: '2%' }}>
                        <div>
                            <IconButton
                                aria-label="menu do usuário"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <Typography variant="body1">{user.nome}</Typography>
                                <KeyboardArrowDown />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleAlterarDados}>Alterar Dados</MenuItem>
                                <MenuItem onClick={handleAlterarSenha}>Alterar Senha</MenuItem>
                                <MenuItem onClick={logout}>Sair</MenuItem>
                            </Menu>
                        </div>

                        <NotificationMenu />
                    </div>
                )}
            </Toolbar>
        </AppBar>
    );
};

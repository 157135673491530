import React from 'react';
import { Box, Typography, ListItemButton, List, ListItemText } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { capitalizerFirstLetter } from '../../utils';
import { ButtonFL } from '../../components/ui/ButtonFL';

export const SideBar = ({ feeds, handleFeedClick, handleAddFeed }) => {
    return (
        <Box
            sx={{
                width: '12%',
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                bgcolor: 'var(--white-color)',
                // height: '100vh',
            }}
        >
            <List subheader={<Typography variant="h4">Feeds</Typography>} sx={{ width: '100%' }}>
                {feeds.map((feed) => (
                    <ListItemButton key={feed.id} onClick={() => handleFeedClick(feed)}>
                        <ListItemText primary={capitalizerFirstLetter(feed?.nome)} />
                    </ListItemButton>
                ))}
            </List>

            <ButtonFL
                sx={{
                    mt: 'auto',
                }}
                textKey=" Criar Feed"
                variant="contained"
                onClick={handleAddFeed}
                startIcon={<AddIcon fontSize="small" />}
            />
        </Box>
    );
};

import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import GavelIcon from '@mui/icons-material/Gavel';
import { TikTokIcon } from '../_assets/icons/TikTokIcon';

const iconsCompanies = {
    instagram: <InstagramIcon />,
    youtube: <YouTubeIcon />,
    twitter: <TwitterIcon />,
    tiktok: TikTokIcon(''),
    direitos: <GavelIcon />,
};

export const getIconByCategory = (category) => {
    if (category?.toLowerCase() in iconsCompanies) {
        return iconsCompanies[category.toLowerCase()];
    }
};

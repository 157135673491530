import { Get } from '../http/httpVerbs';

export const apiGetFeed = async (id) => {
    try {
        const response = await Get(`${process.env.REACT_APP_API_URL}/feeds/${id}`);

        return response[0];
    } catch (error) {
        throw error;
    }
};

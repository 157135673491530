import React, { useState, useEffect } from 'react';
import DevicesIcon from '@mui/icons-material/Devices';
import { Box, Typography } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { isBefore, isEqual } from 'date-fns';
import { GenericModal } from '../../ui/Modal';
import { AccordionFL } from '../../ui/AccordionFL';
import { TextEditor } from '../../ui/TextEditor';
import { TitleFL } from '../../ui/TitleFL';
import { getIconByCategory, getStatusStages, formatDate } from '../../../utils';
import { Chats } from '../../chat/Chats';
import { IcampanhasItensStatus } from '../../../data/Interfaces';
import { CampainWorkflowStepDetails } from './CampainWorkflowStepDetails';
import { CampainWorkflowSteps } from './CampainWorkflowSteps';
import { FlagStatus } from '../FlagStatus';
import { apiPostApprovedCampaign } from '../../../services/apiPostApprovedCampaign';
import { apiPostReprovedCampaign } from '../../../services/apiPostReprovedCampaign';
import { CampanhaItensReprova } from '../../campanha/CampanhaItensReprova';
import { useErrorStore } from '../../../store';
import { apiGetVideoInformations } from '../../../services';

export const CampainCardDetailsModal = ({ item, open, setUpdated, children, onClose, campanhaItens, setIsLoading }) => {
    const [campain, setCampain] = useState(item);
    const [openEditWorkflowModal, setOpenEditWorkflowModal] = useState({ show: false, children: false, etapa: [] });
    const [flagData, setFlagData] = useState(null);
    const [openModalReprove, setOpenModalReprove] = useState(false);
    const [videoInfo, setVideoInfo] = useState({});
    const icon = getIconByCategory(item?.categoria);

    const completedDate = formatDate(item?.completedDate);
    const completedDateRaw = new Date(item?.completedDate);
    const currentDate = new Date();

    const briefing = decodeURIComponent(item?.escopoBriefing || item?.campanhaBriefing || '');

    const showError = useErrorStore((state) => state.showError);

    const isPastDue =
        completedDate && (isBefore(completedDateRaw, currentDate) || isEqual(completedDateRaw, currentDate));
    const color =
        isPastDue && item?.status !== IcampanhasItensStatus.COMPLETED ? 'var(--error-color)' : 'var(--black-color)';

    const handleModal = async ({ etapa, children = false }) => {
        try {
            if (etapa?.dados?.length) {
                if (etapa?.dados[0]?.nome == 'videoFile') {
                    const data = await apiGetVideoInformations(item?.idVideo);
                    setVideoInfo(data);
                }

                setOpenEditWorkflowModal({ etapa: etapa, children: children, show: true });
            }
        } catch (error) {
            showError(error);
        }
    };

    const handleModalClose = async () => {
        setOpenEditWorkflowModal({ etapa: [], children: children, show: false });
    };

    const handleModalReprovedClose = async () => {
        setOpenModalReprove(false);
    };

    const handleOnAproveCampanhaItem = async (id) => {
        try {
            setIsLoading(true);
            await apiPostApprovedCampaign(id);
            setUpdated((prevUpdated) => !prevUpdated);
        } catch (error) {
            showError(error);
        } finally {
            onClose();
            setIsLoading(false);
        }
    };

    const handleOnReproveCampanhaItem = async (id, reprovedMessage) => {
        try {
            setIsLoading(true);
            await apiPostReprovedCampaign(id, reprovedMessage);
            setUpdated((prevUpdated) => !prevUpdated);
            handleModalReprovedClose();
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleOpenItemReproveModal = () => {
        setOpenModalReprove(true);
    };

    useEffect(() => {
        setCampain(item);
    }, [item?.id]);

    useEffect(() => {
        const getStatusInfo = () => {
            const statusInfo = getStatusStages(campain?.status);
            setFlagData(statusInfo);
        };
        getStatusInfo();
    }, [campain?.status]);

    return (
        <>
            <GenericModal title={`${item.categoria} - ${item.subcategoria}`} open={open} onClose={onClose}>
                <>
                    <TitleFL
                        title={item.titulo || item.categoria}
                        subtitle={item.titulo ? `${item.subcategoria} ${item.categoria}` : item.subcategoria}
                        iconSubtitle={icon}
                        titleSize="h4"
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        {completedDate && (
                            <>
                                <Typography variant="caption" sx={{ mr: 1 }}>
                                    Data de entrega:
                                </Typography>
                                <FlagStatus color={color} textWithoutT={completedDate} fontSize="0.9rem" />
                            </>
                        )}
                    </Box>

                    <Box sx={{ my: 2 }}>
                        <AccordionFL
                            title="Briefing da campanha"
                            description={item?.escopoBriefing ?? item?.campanhaBriefing}
                            icon={<DescriptionOutlinedIcon />}
                        >
                            <Box>
                                <TextEditor editorContent={briefing} isReadOnly />
                            </Box>
                        </AccordionFL>
                    </Box>
                    <Box
                        sx={{
                            bgcolor: 'var(--primary-dark-color)',
                            p: 2,
                            borderRadius: 2,
                            boxSizing: 'border-box',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginBottom: 2,
                                color: 'var(--white-color)',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <TitleFL title="Entregas" iconTitle={<DevicesIcon />} />
                            </Box>
                            <Box>{flagData && <FlagStatus color={flagData.color} text={flagData.text} />}</Box>
                        </Box>

                        <Box sx={{ my: 3 }}>
                            <TitleFL title="Registro de envios:" color={'var(--white-color)'} titleSize="body1" />
                        </Box>

                        <CampainWorkflowSteps item={item} handleModal={handleModal} />
                    </Box>
                    <Box sx={{ bgcolor: 'var(--white-color)', borderRadius: 2, p: 2, marginTop: 2 }}>
                        <Chats title="Fale com a Equipe FLCO" item={item} contexto="ADMIN_CLIENTE_CAMPANHAITEM" />
                    </Box>
                </>
            </GenericModal>

            {openEditWorkflowModal.show && (
                <CampainWorkflowStepDetails
                    open={openEditWorkflowModal.show}
                    item={campain}
                    videoInfo={videoInfo}
                    onClose={handleModalClose}
                    etapa={openEditWorkflowModal.etapa}
                    handleOnAproveCampanhaItem={handleOnAproveCampanhaItem}
                    handleOpenItemReproveModal={handleOpenItemReproveModal}
                    campanhaItens={campanhaItens}
                />
            )}

            {openModalReprove && (
                <CampanhaItensReprova
                    item={campain}
                    onClose={handleModalReprovedClose}
                    handleOnReproveCampanhaItem={handleOnReproveCampanhaItem}
                />
            )}
        </>
    );
};

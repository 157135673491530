import React from 'react';

import { create } from 'zustand';
import { ErrorModal } from '../components/error/ErrorModal';
// import { logError } from '../components/error/ErrorHandler';

export const useErrorStore = create((set) => ({
    error: {},
    isErrorModalOpen: false,
    showError: (error) => set({ error, isErrorModalOpen: true }),
    handleCloseErrorModal: () => set({ isErrorModalOpen: false }),
}));

export const ErrorProvider = ({ children }) => {
    const { error, isErrorModalOpen, handleCloseErrorModal } = useErrorStore();

    return (
        <>
            {children}
            <ErrorModal open={isErrorModalOpen} handleClose={handleCloseErrorModal} error={error} />
        </>
    );
};

import * as React from 'react';
import { Route, Routes as RouterRoutes, useLocation } from 'react-router-dom';

import { Dashboard } from '../pages/dashboard/Dashboard';
import { Campanhas } from '../components/campanhas/Campanhas';
import { Campanha } from '../components/campanha/Campanha';
import { ForgotPassword } from '../pages/forgotPassword';
import { AlterarDados } from '../pages/AlterarDados';
import { CreateNewPassword } from '../pages/createNewPassword';
import { Feeds } from '../pages/feeds';
import { Videos } from '../pages/videos';

export const Routes = () => {
    const { pathname: URL } = useLocation();
    const token = URL.substring(URL.lastIndexOf('/') + 1);

    return (
        <>
            <RouterRoutes>
                <Route
                    path={'/omnivideos'}
                    element={
                        <Dashboard>
                            <Feeds />
                        </Dashboard>
                    }
                />
                <Route
                    path={'/'}
                    element={
                        <Dashboard>
                            <Campanhas />
                        </Dashboard>
                    }
                />
                <Route
                    path={'/projetos'}
                    element={
                        <Dashboard>
                            <Campanhas />
                        </Dashboard>
                    }
                />
                <Route
                    path={'projetos/:id'}
                    element={
                        <Dashboard>
                            <Campanha />
                        </Dashboard>
                    }
                />
                <Route
                    path={'projetos/novo'}
                    element={
                        <Dashboard>
                            <Campanha />
                        </Dashboard>
                    }
                />
                <Route
                    path="/alterarDados"
                    element={
                        <Dashboard>
                            <AlterarDados />
                        </Dashboard>
                    }
                />
                <Route
                    path={'/videos'}
                    element={
                        <Dashboard>
                            <Videos />
                        </Dashboard>
                    }
                />
                <Route path="/forgotPassword" element={<ForgotPassword />} />
                <Route path="/createNewPassword" element={<CreateNewPassword />} />
                <Route path={`createNewPassword/${token}`} element={<CreateNewPassword />} />
            </RouterRoutes>
        </>
    );
};

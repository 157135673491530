import { Delete } from '../http/httpVerbs';

export const apiDeleteFeed = async (id) => {
    try {
        const response = await Delete(`${process.env.REACT_APP_API_URL}/feeds/${id}`);

        return response;
    } catch (error) {
        throw error;
    }
};

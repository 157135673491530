export const Get = async (url) => {
    try {
        const response = await fetch(url, {
            headers: {
                Authorization: process.env.REACT_APP_TOKEN,
                idcliente: getDataFromLocalStorage('cliente'),
                idclienteusuario: getDataFromLocalStorage('id'),
            },
        });

        const responseData = await response.json();

        if (response.status !== 200) {
            throw responseData;
        }

        return responseData;
    } catch (error) {
        throw error;
    }
};

export const Post = async (url, data) => {
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN,
                idcliente: getDataFromLocalStorage('cliente'),
                idclienteusuario: getDataFromLocalStorage('id'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        const responseData = await response.json();

        if (response.status !== 200) {
            throw responseData;
        }

        return responseData;
    } catch (error) {
        throw error;
    }
};

export const Patch = async (url, data) => {
    try {
        const isFormData = data instanceof FormData;
        const headers = {
            Authorization: process.env.REACT_APP_TOKEN,
            idcliente: getDataFromLocalStorage('cliente'),
            idclienteusuario: getDataFromLocalStorage('id'),
        };

        if (!isFormData) {
            headers['Content-Type'] = 'application/json';
            data = JSON.stringify(data);
        }

        const response = await fetch(url, {
            method: 'PATCH',
            headers: headers,
            body: data,
        });

        if (!response.ok) {
            const responseData = await response.json();
            throw responseData;
        }

        return response.json();
    } catch (error) {
        throw error;
    }
};

export const Download = async (url) => {
    try {
        const response = await fetch(url, {
            headers: {
                Authorization: process.env.REACT_APP_TOKEN,
                idcliente: getDataFromLocalStorage('cliente'),
                idclienteusuario: getDataFromLocalStorage('id'),
            },
        });

        if (response.status !== 200) {
            throw response;
        }

        return response.text();
    } catch (error) {
        throw error;
    }
};

export const Delete = async (url) => {
    try {
        const response = await fetch(url, {
            headers: {
                Authorization: process.env.REACT_APP_TOKEN,
                idcliente: getDataFromLocalStorage('cliente'),
                idclienteusuario: getDataFromLocalStorage('id'),
            },
            method: 'DELETE',
        });

        const responseData = await response.json();

        if (response.status !== 200) {
            throw responseData;
        }

        return responseData;
    } catch (error) {
        throw error;
    }
};

export const getUserKey = () => {
    const isDevEnvironment = window.location.hostname.includes('dev');
    return isDevEnvironment ? 'dev_clientes_user' : 'prod_clientes_user';
};

const getDataFromLocalStorage = (field) => {
    const storedUser = localStorage.getItem(getUserKey());
    if (storedUser) {
        try {
            const user = JSON.parse(storedUser);
            return user[field] ? user[field] : null;
        } catch (e) {
            console.log('❌ Erro ao analisar o user do localStorage', e);
            return null;
        }
    } else {
        return null;
    }
};

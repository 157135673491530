import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { menus } from '../../data/Menus';
import { useAuthStore } from '../../store';
import { useMobileDetect } from '../../hooks';

export const ListItems = ({ open }) => {
    const user = useAuthStore((state) => state.user);
    const isMobile = useMobileDetect();

    return (
        <List
            component="nav"
            sx={{
                display: 'flex',
                flexDirection: { xs: 'row', md: 'column' },
                color: 'var(--white-color)',
            }}
        >
            {menus.map(
                (menu, index) =>
                    user.acesso >= menu.accessLevel && (
                        <ListItemButton
                            key={index}
                            to={menu.link}
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                ml: 1,
                                py: 1.5,
                                '&:hover': {
                                    backgroundColor: 'var(--primary-color)',
                                },
                            }}
                        >
                            {menu.icon && (
                                <ListItemIcon title={menu.title} sx={{ color: 'var(--white-color)', minWidth: 'auto' }}>
                                    <menu.icon />
                                </ListItemIcon>
                            )}
                            {(open || isMobile) && <ListItemText primary={menu.title} sx={{ textAlign: 'center' }} />}
                        </ListItemButton>
                    ),
            )}
        </List>
    );
};

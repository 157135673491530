import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

export const TextViewer = ({ text, hasTitle = true, height = '70vh' }) => {
    const paragraphs = text?.split('\n');

    return (
        <Paper
            elevation={3}
            style={{
                padding: '10px',
                margin: '10px',
                overflowY: 'scroll',
                height,
                boxShadow: 'none',
                boxSizing: 'border-box',
            }}
        >
            <Box sx={{ padding: '15px' }}>
                {hasTitle && (
                    <Typography variant="h6" sx={{ mb: 2, fontFamily: 'var(--font-family-bold)' }}>
                        Roteiro:
                    </Typography>
                )}
                {paragraphs?.map((paragraph, index) => (
                    <Typography key={index} variant="body1" paragraph sx={{ fontSize: '14px' }}>
                        {paragraph}
                    </Typography>
                ))}
            </Box>
        </Paper>
    );
};

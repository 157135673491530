export const getStatusCampain = (value) => {
    let statusCampain = {};

    if (value === 0) {
        // statusCampain.color = 'var(--error-color)';
        statusCampain.color = 'var(--grey-400-color)';
        statusCampain.status = 'Aguardando';
    } else if (value < 100) {
        statusCampain.color = 'var(--warning-color)';
        statusCampain.status = 'Em andamento';
    } else {
        statusCampain.color = 'var(--success-color)';
        statusCampain.status = 'Concluído';
    }

    return statusCampain;
};

export const getStatusStages = (itemStatus) => {
    const statusMap = {
        APPROVED: { text: 'Concluído', color: 'var(--success-color)' },
        APPROVED_CLIENT: { text: 'Concluído', color: 'var(--success-color)' },
        COMPLETED: { text: 'Concluído', color: 'var(--success-color)' },
        // REPROVED: { text: 'Reprovado', color: 'var(--error-color)' },
        REPROVED_CLIENT: { text: 'Reprovado pelo Cliente ', color: 'var(--error-color)' },
        // WAITING_FOR_APPROVAL: { text: 'Aguardando aprovação', color: 'var(--warning-color)' },
        WAITING_FOR_CLIENTE_APPROVAL: { text: 'Aguardando sua Aprovação', color: 'var(--warning-color)' },
    };

    return statusMap[itemStatus];
};

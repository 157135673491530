import * as React from 'react';
import { Box, Alert } from '@mui/material';
import { DashboardBottomBar } from './DashboardBottomBar';
import { useMobileDetect } from '../../hooks';
import { Login } from '../login';
import './dashboard.css';
import { Header } from '../../components/ui/Header';
import { useAuthStore } from '../../store';
import { DashboardDrawerMenu } from '../../components/ui/DashboardDrawerMenu';
import { showMessage } from '../../data/Interfaces';

export const Dashboard = ({ children }) => {
    const user = useAuthStore((state) => state.user);
    const logout = useAuthStore((state) => state.logout);
    const isMobile = useMobileDetect();

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState({ message: '' });

    const toggleDrawer = () => {
        setOpen(!open);
    };

    React.useEffect(() => {
        if (user && !user.cliente) {
            showMessage(
                {
                    variant: 'error',
                    message: 'Você foi deslogado pois não está associado a nenhum cliente.',
                },
                setMessage,
            );
            logout();
        }
    }, [user, logout]);

    return (
        <>
            {message.message && <Alert severity="error">{message.message}</Alert>}
            {user != null && user?.cliente ? (
                <>
                    {isMobile && <Header />}
                    <Box className="dash-container">
                        {!isMobile && <DashboardDrawerMenu toggleDrawer={toggleDrawer} open={open} />}
                        <Box
                            component="main"
                            sx={{
                                backgroundColor: 'var(--grey-100-color)',
                                width: '100%',
                                minHeight: '100vh',
                            }}
                        >
                            {children}
                        </Box>
                        {isMobile && <DashboardBottomBar />}
                    </Box>
                </>
            ) : (
                <>
                    <Login />
                </>
            )}
        </>
    );
};

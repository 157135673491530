import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Grid, Autocomplete, TextField } from '@mui/material';
import { CampainCardDetailsModal } from '../../ui/CampainCardDetailsModal';
import {
    handleOpenCampanhaItemFromNotification,
    storageCampanhaItem,
    handleClearStorage,
} from '../../../utils/handleStorage.utils';
import { CampainCard } from '../../CampainCard';
import { placeholder } from '../../../utils';
import { useMobileDetect } from '../../../hooks';
import ButtonFilter from '../../ui/ButtonFilter';

export const EntregaveisTab = ({ campanhaItens, setIsLoading, setUpdated }) => {
    const [openModal, setOpenModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [filter, setFilter] = useState('');
    const [itemsLoaded, setItemsLoaded] = useState(false);
    const isMobile = useMobileDetect();

    const uniqueCreators = Array.from(new Set(campanhaItens.map((item) => item.creatorName ?? 'Vazio')));

    const handleOpenModal = (item) => {
        setSelectedItem(item);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setSelectedItem(null);
        setOpenModal(false);
    };

    useEffect(() => {
        if (campanhaItens && campanhaItens.length > 0) {
            setItemsLoaded(true);
        }
    }, [campanhaItens]);

    useEffect(() => {
        if (itemsLoaded) {
            const shouldOpen = handleOpenCampanhaItemFromNotification();
            if (shouldOpen && storageCampanhaItem) {
                handleOpenModal(campanhaItens.filter((i) => i.id === storageCampanhaItem)[0]);
                handleClearStorage();
            }
        }
    }, [itemsLoaded]);

    return (
        <Box>
            <Typography variant="h4" component="h2" gutterBottom>
                Todas as entregas
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginBottom: 2 }}>
                {uniqueCreators.length <= 6 ? (
                    <>
                        <ButtonFilter onClick={() => setFilter('')}>Todos</ButtonFilter>
                        {uniqueCreators.map((creatorName) => (
                            <ButtonFilter
                                key={creatorName}
                                onClick={() => setFilter(creatorName ?? placeholder)}
                                onChange
                            >
                                {creatorName ?? placeholder}
                            </ButtonFilter>
                        ))}
                    </>
                ) : (
                    <Autocomplete
                        options={uniqueCreators}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                            <TextField {...params} label="Filtrar por creator" fullWidth variant="outlined" />
                        )}
                        onChange={(event, newValue) => setFilter(newValue ?? '')}
                        renderOption={(props, option) => <li {...props}>{option}</li>}
                        sx={{ width: isMobile ? '100%' : 700, my: 4 }}
                    />
                )}
            </Box>

            <Grid container width="100%" justifyContent="left">
                {campanhaItens.length > 0 &&
                    campanhaItens
                        .filter((item) => {
                            if (!filter) {
                                return filter === '';
                            }

                            if (filter === 'Vazio' && uniqueCreators.length >= 6) {
                                return !item.creatorName;
                            }

                            return item.creatorName === filter;
                        })
                        .map((item, index) => {
                            if (item.categoria.startsWith('Direitos') || item.categoria.startsWith('direitos')) {
                                return null;
                            }

                            return <CampainCard key={index} campain={item} handleOpenModal={handleOpenModal} />;
                        })}
            </Grid>

            {openModal && (
                <CampainCardDetailsModal
                    item={selectedItem}
                    open={openModal}
                    onClose={handleCloseModal}
                    setIsLoading={setIsLoading}
                    setUpdated={setUpdated}
                />
            )}
        </Box>
    );
};

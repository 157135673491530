import React, { useEffect, useState } from 'react';
import { Container, Box, Typography } from '@mui/material';
import { Get } from '../../http/httpVerbs';
import { Loader } from '../ui/Loader';
import { CampanhaItem } from './CampanhaItem';
import { useMobileDetect } from '../../hooks';
import './campanhas.css';
import { useAuthStore, useErrorStore } from '../../store';

export const Campanhas = () => {
    const [campanhas, setCampanhas] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const user = useAuthStore((state) => state.user);
    const isMobile = useMobileDetect();
    const showError = useErrorStore((state) => state.showError);

    useEffect(() => {
        const fetchData = async () => {
            if (user?.cliente > 0) {
                try {
                    const dataCampanhas = await Get(
                        `${process.env.REACT_APP_API_URL}/campanhas/?cliente=${user.cliente}`,
                    );
                    setCampanhas(dataCampanhas);
                } catch (error) {
                    showError(error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        if (user) fetchData();
    }, [user?.cliente, showError]);

    if (!user) {
        return <Typography variant="h6">Carregando informações do usuário...</Typography>;
    }

    const titles = ['Título', 'Progresso', ' '];

    return (
        <>
            {isLoading && <Loader />}
            <Container maxWidth="xl" disableGutters={true} sx={{ m: 1, py: 4 }}>
                {!isMobile && (
                    <Typography
                        variant="h4"
                        sx={{
                            color: 'var(--primary-dark-color)',
                            px: 3,
                            pb: 2,
                        }}
                    >
                        Lista de campanhas
                    </Typography>
                )}
                <Box>
                    {campanhas?.map((campanha, index) => (
                        <CampanhaItem key={index} campanha={campanha} />
                    ))}
                </Box>
            </Container>
        </>
    );
};

import React from 'react';
import { Button } from '@mui/material';

const ButtonFilter = (props) => {
    return (
        <Button
            {...props}
            size="small"
            sx={{
                my: 2,
                px: 1.5,
                fontSize: '0.8rem',
                borderRadius: '20px',
                backgroundColor: 'var(--primary-dark-color)',
                color: 'var(--white-color)',
                '&:hover': {
                    backgroundColor: 'var(--primary-light-color)',
                },
                ...props.sx,
            }}
        >
            {props.children}
        </Button>
    );
};

export default ButtonFilter;

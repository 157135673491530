import { Box, Modal, TextField, Typography, FormControlLabel, Radio, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ButtonFL } from '../../components/ui/ButtonFL';

export const FeedsItemModal = ({
    video,
    isVideoSettingsOpen,
    setVideoSettingsOpen,
    handleVideoChange,
    saveFeed,
    selectedFeed,
}) => {
    return (
        <Modal open={isVideoSettingsOpen} onClose={() => setVideoSettingsOpen(false)}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <IconButton
                    onClick={() => setVideoSettingsOpen(false)}
                    sx={{ float: 'right', color: 'var(--primary-dark-color)' }}
                >
                    <Close />
                </IconButton>
                <Typography variant="h6" gutterBottom>
                    Configurações do Vídeo
                </Typography>
                <FormControlLabel
                    control={<Radio checked={video.isCover} onChange={() => handleVideoChange('isCover', 1)} />}
                    label="Definir como capa"
                />
                <TextField
                    fullWidth
                    label="Título do Vídeo"
                    value={video.titulo}
                    onChange={(e) => handleVideoChange('titulo', e.target.value)}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    label="CTA do Vídeo"
                    value={video.cta}
                    onChange={(e) => handleVideoChange('cta', e.target.value)}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    label="Link do Vídeo"
                    value={video.link || ''}
                    onChange={(e) => handleVideoChange('link', e.target.value)}
                    sx={{ mb: 2 }}
                />

                <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <ButtonFL variant="contained" onClick={() => saveFeed(selectedFeed.id, selectedFeed)}>
                        Salvar Alterações
                    </ButtonFL>
                </Box>
            </Box>
        </Modal>
    );
};

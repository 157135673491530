import { Post } from '../http/httpVerbs';

export const apiPostFeed = async (feed) => {
    try {
        const response = await Post(`${process.env.REACT_APP_API_URL}/feeds`, feed);

        return response;
    } catch (error) {
        throw error;
    }
};

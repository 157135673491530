import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Tabs, Tab, Fab, Badge } from '@mui/material';
import { Chat } from '@mui/icons-material';
import { Get } from '../../http/httpVerbs';
import { BriefingTab } from '../tabs/BriefingTab';
import { Loader } from '../ui/Loader';
import { ChatModal } from '../ChatModal';
import { useAuthStore, useNotificationStore, useErrorStore } from '../../store';
import { PendentesTab } from '../tabs/PendentesTab';
import { VideosTab } from '../tabs/VideosTab';
import { formatDate } from '../../utils';
import { IcampanhasItensStatus } from '../../data/Interfaces';
import { EntregaveisTab } from '../tabs/EntregaveisTab';

export const Campanha = () => {
    const { id } = useParams();
    const [campanha, setCampanha] = useState({});
    const [campanhaItens, setCampanhaItens] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(2);
    const [updated, setUpdated] = useState(false);
    const [chatModalOpen, setChatModalOpen] = useState(false);
    const [clientNotifications, setClientNotifications] = useState(0);
    const user = useAuthStore((state) => state.user);

    const { notifications, getNotificationsByContext } = useNotificationStore((state) => ({
        notifications: state.notifications,
        fetchNotifications: state.fetchNotifications,
        getNotificationsByContext: state.getNotificationsByContext,
    }));

    const showError = useErrorStore((state) => state.showError);

    const handleChatButtonClick = () => {
        setChatModalOpen(true);
    };

    const handleCloseChat = () => {
        setChatModalOpen(false);
    };

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
        setUpdated((prevUpdated) => !prevUpdated);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const dataCampanha = await Get(`${process.env.REACT_APP_API_URL}/campanhas/${id}`);

                if (dataCampanha) {
                    setCampanha(dataCampanha);
                }

                let url = `${process.env.REACT_APP_API_URL}/campanhasItens?campanha=${id}&direitos=false`;

                if (activeTab === 2) {
                    url += `&status=${IcampanhasItensStatus.WAITING_CLIENT}`;
                }

                const dataCampanhaItensResponse = await Get(url);

                if (dataCampanhaItensResponse) {
                    const updatedCampanhaItens = dataCampanhaItensResponse.map((item) => {
                        return { ...item, campanhaBriefing: dataCampanha.briefing };
                    });

                    setCampanhaItens(updatedCampanhaItens);
                }

                setClientNotifications(getNotificationsByContext('ADMIN_CLIENTE_CAMPANHA'));
            } catch (error) {
                showError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id, updated, activeTab]);

    useEffect(() => {
        setClientNotifications(getNotificationsByContext('ADMIN_CLIENTE_CAMPANHA'));
    }, [notifications]);

    return (
        <>
            {isLoading && <Loader />}
            <Box
                sx={{
                    flexGrow: 1,
                    bgcolor: 'var(--white-color)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 3,
                }}
            >
                <Typography variant="h4" letterSpacing={1}>
                    {campanha.clienteNome} - {campanha.titulo}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Criado em {formatDate(campanha.created_at)}
                </Typography>

                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                    <Tabs
                        value={activeTab}
                        onChange={handleChange}
                        aria-label="basic tabs example "
                        variant="scrollable"
                    >
                        <Tab label="Briefing" />
                        <Tab label="Todas as entregas" />
                        <Tab label="Minhas pendências" />
                        <Tab label="Vídeos aprovados" />
                    </Tabs>
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        minHeight: '100vh',
                        p: 4,
                        mb: { xs: 20 },
                        '@media (max-width: 600px)': { mt: 3, p: 0 },
                    }}
                >
                    {activeTab === 0 && (
                        <BriefingTab setIsLoading={setIsLoading} campanha={campanha} setUpdated={setUpdated} />
                    )}
                    {activeTab === 1 && (
                        <EntregaveisTab
                            setIsLoading={setIsLoading}
                            campanhaItens={campanhaItens}
                            setUpdated={setUpdated}
                        />
                    )}
                    {activeTab === 2 && (
                        <PendentesTab
                            setIsLoading={setIsLoading}
                            campanhaItens={campanhaItens}
                            setUpdated={setUpdated}
                        />
                    )}
                    {activeTab === 3 && (
                        <VideosTab setIsLoading={setIsLoading} campanhaItens={campanhaItens} setUpdated={setUpdated} />
                    )}
                </Box>

                <ChatModal
                    headerTitle={user.nome}
                    item={campanha}
                    openChat={chatModalOpen}
                    toggleDrawer={handleCloseChat}
                    contexto="ADMIN_CLIENTE_CAMPANHA"
                />

                <Fab
                    color="error"
                    aria-label="chat"
                    sx={{ position: 'fixed', bottom: 30, right: 30, bgcolor: 'var(--primary-dark-color)' }}
                    onClick={handleChatButtonClick}
                >
                    <Badge badgeContent={clientNotifications} color="secondary">
                        <Chat />
                    </Badge>
                </Fab>
            </Box>
        </>
    );
};

import { useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    CssBaseline,
    Grid,
    Link,
    Paper,
    TextField,
    Typography,
    InputAdornment,
    IconButton,
    Container,
    Alert,
} from '@mui/material';

import { Visibility, VisibilityOff, LockOutlined } from '@mui/icons-material';
import logo from '../../_assets/logo.png';
import { mensagemVazio, showMessage } from '../../data/Interfaces';
import { Loader } from '../../components/ui/Loader';
import { ButtonFL } from '../../components/ui/ButtonFL';
import { alertColor, removeSpacesFromString, emailValidator } from '../../utils';
import { useAuthStore } from '../../store';

export const Login = () => {
    const [message, setMessage] = useState(mensagemVazio);
    const [isLoading, setIsLoading] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');

    const login = useAuthStore((state) => state.login);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const onChangeEmail = (event) => {
        const email = event.target.value;
        setIsEmailValid(emailValidator(email));
    };

    const onChangePassword = (event) => {
        const password = event.target.value;
        setPassword(password);
        setIsPasswordValid(password.length > 5);
    };

    const validateAndEnableButton = () => {
        return isEmailValid && isPasswordValid;
    };

    const handleSubmit = async (event) => {
        try {
            setIsLoading(true);
            event.preventDefault();

            const data = new FormData(event.currentTarget);

            const email = removeSpacesFromString(data.get('email'));
            const password = removeSpacesFromString(data.get('password'));

            await login(email, password);
        } catch (error) {
            showMessage(
                {
                    variant: alertColor.error,
                    message: error.message,
                },
                setMessage,
            );
        } finally {
            setPassword('');
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(false);
    }, []);

    return (
        <>
            {isLoading && <Loader />}
            <Grid container component="main" sx={{ height: '100vh', backgroundColor: 'var(--primary-color)' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={6}
                    md={6}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'var(--primary-color)',
                    }}
                >
                    <Box
                        component="img"
                        src={logo}
                        alt="Logo"
                        sx={{
                            maxWidth: '40%',
                            height: 'auto',
                            '@media (max-width: 600px)': {
                                maxWidth: '50%',
                            },
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    component={Paper}
                    elevation={6}
                    square
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Container maxWidth="xs">
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'var(--primary-color)' }}>
                                <LockOutlined />
                            </Avatar>
                            <Typography variant="h4">Login</Typography>
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
                                {message.message && <Alert severity="error">{message.message}</Alert>}

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    placeholder="E-mail"
                                    type="text"
                                    autoFocus
                                    onChange={onChangeEmail}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'var(--secondary-color)',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'var(--primary-color)',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'var(--primary-color)',
                                            },
                                        },
                                    }}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    placeholder="Senha"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    value={password}
                                    autoComplete="current-password"
                                    onChange={onChangePassword}
                                    inputProps={{ minLength: 8 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={togglePasswordVisibility}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'var(--secondary-color)',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'var(--primary-color)',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'var(--primary-color)',
                                            },
                                        },
                                    }}
                                />
                                <ButtonFL
                                    textKey="Entrar"
                                    type="submit"
                                    buttonDisabled={!validateAndEnableButton()}
                                    fullWidth
                                    sx={{
                                        mt: 3,
                                        mb: 2,
                                        backgroundColor: 'var(--primary-color)',
                                        color: 'var(--white-color)',
                                        '&:hover': {
                                            backgroundColor: 'var(--primary-color-dark)',
                                        },
                                    }}
                                />
                                <Grid container>
                                    <Grid item xs>
                                        <Link
                                            href="/forgotPassword"
                                            variant="body2"
                                            sx={{ color: 'var(--primary-color)' }}
                                        >
                                            Esqueci minha senha
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Container>
                </Grid>
            </Grid>
        </>
    );
};

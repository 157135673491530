import React, { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Grid, Container } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AddIcon from '@mui/icons-material/Add';

import { useNavigate } from 'react-router-dom';
import { apiGetFeeds } from '../../services/apiGetFeeds';
import { ButtonFL } from '../../components/ui/ButtonFL';
import { FeedsItem } from './feedsItem';
import { FeedsHeader } from './feedsHeader';
import { ModalConfigFeed } from './modalConfigFeed';
import { ModalAddFeed } from './modalAddFeed';
import { SideBar } from './sideBar';
import { apiPatchFeed } from '../../services/apiPatchFeed';
import { Loader } from '../../components/ui/Loader';
import { apiPostFeed } from '../../services/apiPostFeed';
import { apiDeleteFeed } from '../../services/apiDeleteFeed';
import { useAuthStore, useErrorStore } from '../../store';

export const Feeds = () => {
    const [feeds, setFeeds] = useState([]);
    const [selectedFeed, setSelectedFeed] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [openModalConfig, setOpenModalConfig] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [newFeedName, setNewFeedName] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isGridView, setIsGridView] = useState(true);

    const navigate = useNavigate();

    const { user } = useAuthStore((state) => ({
        user: state.user,
    }));

    const showError = useErrorStore((state) => state.showError);

    const handleFeedClick = (feed) => {
        setSelectedFeed(feed);
    };

    const handleAddFeed = () => {
        setModalOpen(true);
    };

    const handleAddVideo = () => {
        navigate('/videos');
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleNewFeedSave = async () => {
        handleModalClose();

        await saveFeed(null, { nome: newFeedName });

        setNewFeedName(null);
    };

    const moveVideo = (fromIndex, toIndex) => {
        const updatedVideos = [...selectedFeed.FeedsVideos];
        const [movedVideo] = updatedVideos.splice(fromIndex, 1);
        updatedVideos.splice(toIndex, 0, movedVideo);

        const reorderedVideos = updatedVideos.map((video, index) => ({
            ...video,
            ordem: index + 1,
        }));

        setSelectedFeed((prevFeed) => ({
            ...prevFeed,
            FeedsVideos: reorderedVideos,
        }));
    };

    const saveFeed = async (feedId = null, updatedFeed) => {
        try {
            updatedFeed = { ...updatedFeed, cliente: user.cliente };

            setIsLoading(true);

            if (feedId) {
                await apiPatchFeed(feedId, updatedFeed);
            } else {
                await apiPostFeed(updatedFeed);
            }

            setUpdated((prevUpdated) => !prevUpdated);
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async () => {
        try {
            setIsLoading(true);

            if (selectedFeed.id) await apiDeleteFeed(selectedFeed.id);

            setSelectedFeed(null);

            setUpdated((prevUpdated) => !prevUpdated);
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchFeeds = async () => {
            try {
                const clienteFeed = user?.cliente;
                const fetchedFeeds = await apiGetFeeds(clienteFeed);
                setFeeds(fetchedFeeds);
            } catch (error) {
                showError(error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchFeeds();
    }, [updated]);

    const hasFeedsVideos = useMemo(() => selectedFeed?.FeedsVideos.length > 0, [selectedFeed]);

    return (
        <>
            {isLoading && <Loader />}

            <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
                <SideBar feeds={feeds} handleAddFeed={handleAddFeed} handleFeedClick={handleFeedClick} />

                <Container maxWidth="xl" disableGutters sx={{ m: 5 }}>
                    <FeedsHeader
                        setIsGridView={setIsGridView}
                        isGridView={isGridView}
                        setOpenModalConfig={setOpenModalConfig}
                        handleDelete={handleDelete}
                        selectedFeed={selectedFeed}
                    />
                    <ButtonFL
                        sx={{
                            mt: 'auto',
                        }}
                        textKey="Add Video"
                        variant="contained"
                        onClick={handleAddVideo}
                        startIcon={<AddIcon fontSize="small" />}
                    />

                    {hasFeedsVideos ? (
                        <DndProvider backend={HTML5Backend}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: isGridView ? 'row' : 'column',
                                    gap: 2,
                                    my: 3,
                                    flexWrap: 'wrap',
                                }}
                            >
                                {selectedFeed.FeedsVideos.map((video, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <FeedsItem
                                            index={index}
                                            video={video}
                                            moveVideo={moveVideo}
                                            selectedFeed={selectedFeed}
                                            setSelectedFeed={setSelectedFeed}
                                            saveFeed={saveFeed}
                                            isGridView={isGridView}
                                        />
                                    </Grid>
                                ))}
                            </Box>
                        </DndProvider>
                    ) : (
                        <Box sx={{ my: 5 }}>
                            <Typography variant="body1">Selecione um feed para visualizar.</Typography>
                        </Box>
                    )}
                </Container>

                <ModalConfigFeed
                    open={openModalConfig}
                    selectedFeed={selectedFeed}
                    setSelectedFeed={setSelectedFeed}
                    saveFeed={saveFeed}
                    setOpenModalConfig={setOpenModalConfig}
                />
                <ModalAddFeed
                    isModalOpen={isModalOpen}
                    handleModalClose={handleModalClose}
                    setNewFeedName={setNewFeedName}
                    newFeedName={newFeedName}
                    handleNewFeedSave={handleNewFeedSave}
                />
            </Box>
        </>
    );
};

export const mensagemVazio = {
    variant: '',
    message: '',
};

export const showMessage = ({ variant, message }, setMessage) => {
    setMessage({ variant, message });

    setTimeout(() => {
        setMessage(mensagemVazio);
    }, 3000);
};

export const IcampanhasItensStatus = {
    WAITING: 'WAITING_FOR_APPROVAL',
    REPROVED: 'REPROVED',
    APPROVED: 'APPROVED',
    WAITING_CLIENT: 'WAITING_FOR_CLIENTE_APPROVAL',
    REPROVED_CLIENT: 'REPROVED_CLIENT',
    APPROVED_CLIENT: 'APPROVED_CLIENT',
    COMPLETED: 'COMPLETED',
    CANCELED: 'CANCELED',
};

import React, { useEffect, useState } from 'react';
import { Box, Paper, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import { Queue, QueuePlayNext } from '@mui/icons-material';
import { getIconByCategory, alertColor } from '../../../utils';
import { Message } from '../../ui/Message';
import { showMessage, mensagemVazio } from '../../../data/Interfaces';
import { apiGetCampanha } from '../../../services/apiGetCampanha';
import { apiPatchFeed } from '../../../services/apiPatchFeed';
import { apiGetFeed } from '../../../services/apiGetFeed';
import { useAuthStore, useErrorStore } from '../../../store';

const Thumbnail = styled(Paper)({
    position: 'relative',
    width: 250,
    height: 450,
    overflow: 'hidden',
    cursor: 'pointer',
    borderRadius: 15,
    marginRight: 20,
    marginBottom: 20,
    transition: 'transform 0.3s',
    backgroundColor: 'transparent',
    '&:hover .frameSlide': {
        animationPlayState: 'running',
    },
    '&:hover .iconButton': {
        backgroundColor: 'var(--white-color)',
        opacity: 0.2,
    },
});

const Cover = styled(Box)({
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    zIndex: 1,
    transition: 'opacity 0.3s',
});

const FrameSlide = styled(Box)({
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    animation: 'slide 3s steps(10) infinite',
    animationPlayState: 'paused',
    '@keyframes slide': {
        from: { transform: 'translateX(0)' },
        to: { transform: 'translateX(-1000%)' },
    },
});

const IconButtonStyled = styled(IconButton)({
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 999,
});

const Info = styled(Box)({
    position: 'absolute',
    bottom: 20,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '80%',
    backgroundColor: 'var(--grey-50-color)',
    opacity: 0.9,
    color: 'var(--grey-700-color)',
    padding: '10px',
    textAlign: 'left',
    zIndex: 3,
    borderRadius: 10,
    height: 80,
});

const Frame = styled(Box)({
    flex: '0 0 100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

const MessageStyled = styled(Box)({
    zIndex: 1000,
    position: 'absolute',
    top: '30%',
});

export const VideoThumbnail = ({
    item,
    feedsWithoutFilter,
    coverImage,
    frames,
    handleOpenModal,
    setUpdated,
    updated,
    openIn = 'campanhas',
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [message, setMessage] = useState(mensagemVazio);
    const [campanha, setCampanha] = useState({});
    const { user } = useAuthStore((state) => ({
        user: state.user,
    }));

    const feeds = feedsWithoutFilter?.filter((feed) => {
        if (feed?.FeedsVideos) {
            const hasVideo = feed.FeedsVideos.some((feedVideo) => {
                return feedVideo.idVideo === item.idVideo;
            });
            return !hasVideo;
        } else {
            return true;
        }
    });

    const showError = useErrorStore((state) => state.showError);

    const icon = getIconByCategory(item?.categoria);

    const handleAddFeed = async (feedId) => {
        try {
            const feed = await apiGetFeed(feedId);

            feed.FeedsVideos.push({
                idfeed: feed.id,
                idVideo: item.idVideo,
            });

            await apiPatchFeed(feedId, feed);
            setUpdated((prevUpdated) => !prevUpdated);

            showMessage(
                {
                    variant: alertColor.success,
                    message: 'Item adicionado ao feed com sucesso',
                },
                setMessage,
            );

            handleMouseLeave();
            handleMenuClose();
        } catch (error) {
            showError(error);
        }
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleMenuOpen = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleCopyLink = async (event) => {
        try {
            event.stopPropagation();
            const script = `<script src="https://storage.googleapis.com/bucket-videos_infinityfeed/public/FLCO_InfinityFeed-5.0.release.js" charset="utf-8" flshopfeed_client="${campanha?.cliente}" flshopfeed_campaign="${item?.campanha}" flshopfeed_key="${item?.id}"></script>`;
            await navigator.clipboard.writeText(script);
            showMessage(
                {
                    variant: alertColor.success,
                    message: 'Shoppable video copiado',
                },
                setMessage,
            );
            handleMouseLeave();
            handleMenuClose();
        } catch (error) {
            console.log('Erro ao copiar o link: ', error);
        }
    };

    if (openIn != 'campanhas') {
        useEffect(() => {
            const fetchCampanha = async () => {
                try {
                    const campanha = await apiGetCampanha(item?.campanha);
                    setCampanha(campanha);
                } catch (error) {
                    showError(error);
                }
            };

            if (item?.campanha) fetchCampanha();
        }, [item?.campanha]);
    }

    return (
        <Thumbnail
            elevation={3}
            onClick={() => handleOpenModal(item)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
                backgroundImage: isHovered ? `url(${coverImage})` : 'none',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            {message.message && (
                <MessageStyled>
                    <Message variant={message.variant} message={message.message} />
                </MessageStyled>
            )}

            <IconButtonStyled className="iconButton" onClick={handleMenuOpen} onMouseDown={(e) => e.stopPropagation()}>
                <MoreVertIcon />
            </IconButtonStyled>

            <Info>
                <Box sx={{ bgcolor: 'var(--grey-100-color)', textAlign: 'center', borderRadius: 1, mb: 1 }}>
                    <Typography variant="caption">{item?.creatorName}</Typography>
                </Box>

                {openIn == 'campanhas' && (
                    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
                        <Box className="icon">{icon}</Box>
                        <Typography variant="caption" sx={{ mx: 0.5, fontWeight: 600 }}>
                            {item?.titulo ? `${item?.categoria} - ${item?.subcategoria}` : item?.categoria}
                        </Typography>
                    </Box>
                )}

                <Box>
                    <Typography variant="caption">{item?.titulo}</Typography>
                </Box>
            </Info>

            <Cover style={{ backgroundImage: `url(${coverImage})`, opacity: isHovered && frames ? 0 : 1 }} />

            <FrameSlide className="frameSlide" style={{ opacity: isHovered ? 1 : 0 }}>
                {frames?.map((frame, index) => (
                    <Frame key={index} style={{ backgroundImage: `url(${frame})` }} />
                ))}
            </FrameSlide>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {openIn == 'campanhas' ? (
                    <MenuItem onClick={handleCopyLink}>Copiar código Shoppable Video</MenuItem>
                ) : feeds.length > 0 ? (
                    feeds.map((feed) => (
                        <MenuItem key={feed.id} onClick={() => handleAddFeed(feed.id)}>
                            <QueuePlayNext sx={{ mr: 1 }} /> {feed.nome}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem disabled>Nenhum feed disponível para adicionar</MenuItem>
                )}
            </Menu>
        </Thumbnail>
    );
};

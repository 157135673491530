import { create } from 'zustand';
import { getUserKey, Post } from '../http/httpVerbs';

export const useAuthStore = create((set, get) => ({
    user: null,

    fetchUser: () => {
        const storedUser = localStorage.getItem(getUserKey());
        if (storedUser) {
            set({ user: JSON.parse(storedUser) });
        }
    },

    login: async (username, password) => {
        if (!username) throw new Error('E-mail é obrigatório');
        if (!password) throw new Error('Senha é obrigatório');

        const data = await Post(`${process.env.REACT_APP_API_URL}/usuarios/login`, { email: username, password });
        set({ user: data.user });
        localStorage.setItem(getUserKey(), JSON.stringify(data.user));
    },

    logout: () => {
        localStorage.removeItem(getUserKey());
        set({ user: null });
    },

    updateUser: (newUserData) => {
        get().logout();

        set({ user: newUserData });
        localStorage.setItem(getUserKey(), JSON.stringify(newUserData));
    },
}));

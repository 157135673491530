import { Box } from '@mui/material';
import { VideoPlayer } from '../VideoPlayer';
import { ImageViewer } from '../../ImageViewer';
import { FileDownload } from '../FileDownload';
import { TextViewer } from '../../TextViewer';

export const MediaViewer = ({ isVideo, isImage, isDownloadFile, isText, typeFile, etapa }) => (
    <Box sx={{ height: '70vh', width: '100%' }}>
        {isVideo && (
            <VideoPlayer item={etapa} url={etapa?.dados[0]?.valor} playing={typeFile === 'video'} showControls />
        )}
        {isImage && <ImageViewer imageUrl={etapa?.dados[0]?.valor} />}
        {isDownloadFile && <FileDownload typeFile={typeFile} fileUrl={etapa?.dados[0]?.valor} />}
        {isText && <TextViewer text={etapa?.dados[0]?.valor} />}
    </Box>
);

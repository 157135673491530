import React, { useState } from 'react';
import { Card, CardMedia, CardContent, IconButton, Typography, Box, Snackbar } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useDrag, useDrop } from 'react-dnd';
import LaunchIcon from '@mui/icons-material/Launch';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ButtonFL } from '../../components/ui/ButtonFL';
import { capitalizerFirstLetter } from '../../utils';

export const VideoCard = ({ video, index, moveVideo, openVideoSettings, isGridView }) => {
    const [copyMessage, setCopyMessage] = useState(false);

    const [{ isDraggingCurrent }, ref] = useDrag({
        type: 'imagem',
        item: () => ({ index }),
        collect: (monitor) => ({
            isDraggingCurrent: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: 'imagem',
        hover: (item) => {
            if (item.index !== index) {
                moveVideo(item.index, index);
                item.index = index;
            }
        },
    });

    const handleCopyLink = (link) => {
        navigator.clipboard
            .writeText(link)
            .then(() => {
                setCopyMessage(true);
            })
            .catch((err) => {
                console.error('Erro ao copiar o link', err);
            });
    };

    const truncateLink = (link) => {
        const maxLength = isGridView ? 30 : 50;
        if (link.length <= maxLength) return link;
        return link.substring(0, maxLength - 3) + '...';
    };

    return (
        <Card
            sx={{
                width: isGridView ? '253px' : 'auto',
                maxWidth: isGridView ? 'none' : '80%',
                display: 'flex',
                flexDirection: isGridView ? 'column' : 'row',
                position: 'relative',
                marginBottom: '8px',
                boxShadow: '0 1px 4px rgba(0, 0, 0, 0.2)',
                opacity: isDraggingCurrent ? 0.5 : 1,
                borderRadius: '8px',
                transition: 'border 0.2s ease, box-shadow 0.2s ease',
                border: isDraggingCurrent ? '2px dashed var(--primary-color)' : 'none',
                overflow: 'hidden',
                cursor: 'pointer',
                padding: '18px',
            }}
            ref={(node) => ref(drop(node))}
            key={index}
        >
            <CardMedia
                component="img"
                image={video.capa}
                alt={video.titulo}
                sx={{
                    width: isGridView ? '100%' : '70px',
                    height: isGridView ? '450px' : 'auto',
                    aspectRatio: '16/9',
                    borderRadius: '8px',
                }}
            />

            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: isGridView ? 'flex-start' : 'center',
                    flex: 1,
                    p: 2,
                    m: 0,
                    width: '100%',
                }}
            >
                <Typography variant="body2" color="textSecondary">
                    {capitalizerFirstLetter(video.titulo ?? 'Sem título')}
                </Typography>
                <IconButton onClick={openVideoSettings}>
                    <SettingsIcon />
                </IconButton>
            </CardContent>
        </Card>
    );
};

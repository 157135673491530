import React from 'react';
import {
    Box,
    Button,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { useAuthStore } from '../../store';

export const ModalConfigFeed = ({ open, setOpenModalConfig, selectedFeed, setSelectedFeed, saveFeed }) => {
    const { user } = useAuthStore((state) => ({
        user: state.user,
    }));
    const POSITION_OPTIONS = [
        { value: 'top_left', label: 'Canto superior esquerdo' },
        { value: 'top_right', label: 'Canto superior direito' },
        { value: 'bottom_left', label: 'Canto inferior esquerdo' },
        { value: 'bottom_right', label: 'Canto inferior direito' },
        { value: 'inline', label: 'Inline (carrossel)' },
    ];
    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogTitle>Configurações do Feed</DialogTitle>
            <DialogContent>
                <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        label="Nome"
                        value={selectedFeed?.nome}
                        onChange={(e) => {
                            const updatedFeed = { ...selectedFeed, nome: e.target.value };
                            setSelectedFeed(updatedFeed);
                        }}
                        fullWidth
                    />
                    <FormControl fullWidth>
                        <InputLabel id="posicao-label">Posição</InputLabel>
                        <Select
                            labelId="posicao-label"
                            label="Posição"
                            value={selectedFeed?.posicao_capa || ''}
                            onChange={(e) => {
                                const updatedFeed = { ...selectedFeed, posicao_capa: e.target.value };
                                setSelectedFeed(updatedFeed);
                            }}
                        >
                            {POSITION_OPTIONS.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Box sx={{ marginTop: 2 }}>
                        <Typography variant="h6">Código de Embed</Typography>
                        <TextField
                            multiline
                            rows={4}
                            value={`<!-- Omnicontent - Omnifeed -->\n<script src="https://omnifeed.omnicontent.com.br/omnifeed.js" charset="utf-8" data-feed-id="${selectedFeed?.id}" data-auth="${user?.cliente}" data-inline-maxsize="w-250"></script>\n<!-- OmniContent: OmniVideos -->`}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                navigator.clipboard.writeText(
                                    `<!-- Omnicontent - Omnifeed -->\n<script src="https://omnifeed.omnicontent.com.br/omnifeed.js" charset="utf-8" data-feed-id="${selectedFeed?.id}" data-auth="${user?.cliente}" data-inline-maxsize="w-250"></script>\n<!-- OmniContent: OmniVideos -->`,
                                )
                            }
                            sx={{ marginTop: 1 }}
                        >
                            Copiar para Clipboard
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenModalConfig(false)}>Cancelar</Button>
                <Button variant="contained" onClick={() => saveFeed(selectedFeed.id, selectedFeed)}>
                    Salvar Alterações
                </Button>
            </DialogActions>
        </Dialog>
    );
};

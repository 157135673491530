export const getStageCampain = (StageCampain) => {
    const color = 'var(--grey-100-color)';
    const stagesMap = {
        enviaRoteiro: { text: 'Roteiro / Ideia Criativa', color },
        uploadVideo: { text: 'Enviar vídeo', color },
        uploadVideoTexto: { text: 'Enviar vídeo', color },
        sobeLink: { text: 'Confirmar Publicação', color },
        vertical: { text: 'Seleciona Vertical', color },
        completed: { text: 'Enviar vídeo', color },
    };

    return stagesMap[StageCampain];
};

import { Get } from '../http/httpVerbs';

export const apiGetVideoInformations = async (idVideo) => {
    try {
        const response = await Get(`${process.env.REACT_APP_API_URL}/videos/${idVideo}`);

        return response;
    } catch (error) {
        throw error;
    }
};

import { Feed, DvrOutlined, VideoSettings } from '@mui/icons-material';

export const menus = [
    {
        title: 'Meus Projetos',
        link: '/projetos',
        accessLevel: 0,
        icon: DvrOutlined,
    },
    // {
    //     title: 'Vídeos',
    //     link: '/videos',
    //     accessLevel: 0,
    //     icon: VideoSettings,
    // },
    // {
    //     title: 'Omni Vídeo',
    //     link: '/omnivideos',
    //     accessLevel: 0,
    //     icon: Feed,
    // },
];

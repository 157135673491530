import React from 'react';
import { Box, Typography, Modal, TextField } from '@mui/material';

import { ButtonFL } from '../../components/ui/ButtonFL';

export const ModalAddFeed = ({ isModalOpen, handleModalClose, newFeedName, setNewFeedName, handleNewFeedSave }) => {
    return (
        <Modal open={isModalOpen} onClose={handleModalClose}>
            <Box
                sx={{
                    width: '60vh',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography variant="h5" gutterBottom>
                    Criar Feed
                </Typography>
                <TextField
                    fullWidth
                    label="Nome do Feed"
                    value={newFeedName}
                    onChange={(e) => setNewFeedName(e.target.value)}
                    sx={{ mb: 2 }}
                />
                <ButtonFL variant="contained" onClick={handleNewFeedSave}>
                    Salvar
                </ButtonFL>
            </Box>
        </Modal>
    );
};

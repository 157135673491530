export { capitalizerFirstLetter } from './/captalizerFirstLetter.utils';
export { alertColor } from './alertColors.utils';
export { formatDate } from './formatDate.utils';
export { removeSpacesFromString } from './removeSpacesFromString.utils';
export { emailValidator } from './emailValidator.utils';
export { generateLink } from './generateLink.utils';
export { getFirstLetterString } from './getFirstLetterString.utils';
export { getIconByCategory } from './getIconByCategory.utils';
export { getStageCampain } from './getStageCampain.utils';
export { isDisabled } from './isDisabled.utils';
export { identifyFileType } from './identifyFileType.utils';
export { isLightOrDark } from './isLightOrDark.utils';
export * from './getStatusColor.utils';
export * from './forms.utils';

export const placeholder = 'Vazio';

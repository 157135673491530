import { Get } from '../http/httpVerbs';

export const apiGetVideos = async (idcliente) => {
    try {
        const response = await Get(`${process.env.REACT_APP_API_URL}/videos/cliente/${idcliente}`);

        return response;
    } catch (error) {
        throw error;
    }
};

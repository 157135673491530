import React from 'react';
import { Box, Typography } from '@mui/material';

export const TitleFL = ({
    color,
    title,
    titleSize = 'h6',
    iconTitle,
    subtitle,
    subtitleSize = 'body2',
    iconSubtitle,
}) => {
    return (
        <Box
            component="div"
            sx={{
                color,
            }}
        >
            <Box component="div" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                {iconTitle && iconTitle}
                <Typography variant={titleSize} sx={{ mx: 0.5 }}>
                    {title}
                </Typography>
            </Box>
            <Box component="div" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                <Box component="div" sx={{ marginRight: 0.5 }}>
                    {iconSubtitle && iconSubtitle}
                </Box>
                {subtitle && <Typography variant={subtitleSize}>{subtitle}</Typography>}
            </Box>
        </Box>
    );
};

import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import Processando from '../../_assets/Processando.png';
import { VideoThumbnail } from '../../components/ui/VideoThumbnail';
import { apiGetVideos } from '../../services/apiGetVideos';
import { useAuthStore, useErrorStore } from '../../store';
import { Loader } from '../../components/ui/Loader';
import { apiGetFeeds } from '../../services/apiGetFeeds';

export const Videos = () => {
    const [videos, setVideos] = useState([]);
    const [feeds, setFeeds] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [updated, setUpdated] = useState(false);

    const showError = useErrorStore((state) => state.showError);
    const { user } = useAuthStore((state) => ({
        user: state.user,
    }));

    useEffect(() => {
        const fetchFeeds = async () => {
            try {
                const clienteFeed = user?.cliente;
                if (clienteFeed) {
                    const feeds = await apiGetFeeds(clienteFeed);

                    setFeeds(feeds);
                }
            } catch (error) {
                showError(error);
            }
        };

        const fetchVideos = async () => {
            try {
                const result = await apiGetVideos(user.cliente);
                setVideos(result);
            } catch (error) {
                console.error('Error fetching videos:', error);
            }
        };

        fetchFeeds();
        fetchVideos();
    }, [updated]);

    const handleOpenModal = (item) => {
        setSelectedItem(item);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setSelectedItem(null);
        setOpenModal(false);
    };

    return (
        <Box
            sx={{
                height: '95vh',
                bgcolor: 'var(--white-color)',
                p: 3,
            }}
        >
            <Typography variant="h4" component="h2" gutterBottom>
                Vídeos aprovados
            </Typography>
            <Grid container width="100%" justifyContent="left">
                {videos.length === 0 ? (
                    <Loader />
                ) : (
                    videos?.map((item, index) => {
                        const image = item?.capa ? item.capa : Processando;

                        return (
                            <VideoThumbnail
                                key={index}
                                item={item}
                                feedsWithoutFilter={feeds}
                                coverImage={image}
                                frames={item?.videoFile_capas}
                                handleOpenModal={handleOpenModal}
                                setUpdated={setUpdated}
                                updated={updated}
                                openIn={'videos'}
                            />
                        );
                    })
                )}
            </Grid>
        </Box>
    );
};

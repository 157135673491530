import { useState } from 'react';
import { Box } from '@mui/material';
import { FeedsItemModal } from './feedsItemModal';
import { VideoCard } from './videoCard';

export const FeedsItem = ({ video, index, moveVideo, saveFeed, selectedFeed, setSelectedFeed, isGridView }) => {
    const [isVideoSettingsOpen, setVideoSettingsOpen] = useState(false);
    const [selectedFeedItem, setSelectedFeedItem] = useState(null);

    const openVideoSettings = () => {
        setSelectedFeedItem(video);
        setVideoSettingsOpen(true);
    };

    const handleVideoChange = (field, value) => {
        let updatedVideos = selectedFeed.FeedsVideos.map((vid) => {
            if (field === 'isCover') {
                return { ...vid, isCover: vid.idVideo === selectedFeedItem.idVideo };
            } else if (vid.idVideo === selectedFeedItem.idVideo) {
                return { ...vid, [field]: value };
            } else {
                return vid;
            }
        });

        const updatedFeed = {
            ...selectedFeed,
            FeedsVideos: updatedVideos,
        };

        setSelectedFeed(updatedFeed);
        setSelectedFeedItem((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    return (
        <Box>
            <VideoCard
                video={video}
                index={index}
                moveVideo={moveVideo}
                openVideoSettings={openVideoSettings}
                isGridView={isGridView}
            />

            {selectedFeedItem && (
                <FeedsItemModal
                    video={selectedFeedItem}
                    isVideoSettingsOpen={isVideoSettingsOpen}
                    setVideoSettingsOpen={setVideoSettingsOpen}
                    handleVideoChange={handleVideoChange}
                    saveFeed={saveFeed}
                    selectedFeed={selectedFeed}
                />
            )}
        </Box>
    );
};
